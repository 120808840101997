import React from 'react'


import AddressActions from '../../actions/AddressActions.js'

import {Input} from '../UI/index.js'
import NewAddress from '../addresses/NewAddress.js'


class AddressSearch extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            value: props.defaultAddress && props.defaultAddress.street ? `${props.defaultAddress.street} ${props.defaultAddress.nr}${props.defaultAddress.addition}, ${props.defaultAddress.postalCode} ${props.defaultAddress.city} ${props.defaultAddress.country}`.replace(/  +/g, ' ').trim(): '',
            selectedSuggestion: 0,
            suggestions: [],
            timeout: null
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.defaultAddress !== this.props.defaultAddress) {
            this.setState({
                value: this.props.defaultAddress && this.props.defaultAddress.street ? `${this.props.defaultAddress.street} ${this.props.defaultAddress.nr}${this.props.defaultAddress.addition}, ${this.props.defaultAddress.postalCode} ${this.props.defaultAddress.city} ${this.props.defaultAddress.country}`.replace(/  +/g, ' ').trim(): ''
            })
        }
    }

    setValue(event) {
        const value = event.target.value.length === 1 ? event.target.value.toUpperCase() : event.target.value


        if (this.state.timeout) {
            clearTimeout(this.state.timeout)
        }

        const timeout = setTimeout(() => this.autocomplete(this.state.value), 500)

        this.setState({value, timeout}, () => {
            if (!value) {
                const {onChange} = this.props
                onChange(NewAddress())
            }
        })
    }

    autocomplete(value) {
        if (value.length > 3) {
            AddressActions.autocompleteSearch(value, (err, suggestions) => {
                if (!err && suggestions) {
                    this.setState({suggestions, focused: true})
                }
            })
        } else if (!value) {
            this.setState({suggestions: []})
        }
    }

    onChooseSuggestion(event) {
        const {suggestions} = this.state
        const {onChange} = this.props


        const suggestion = suggestions.find((sug) => sug.value === event.target.value)

        if (suggestion) {
            onChange(suggestion)
        }
    }

    render() {
        const {value, suggestions} = this.state
        const {id = '', label='Adres', disabled, readOnly, address} = this.props

        return (
            <Input
                id={`searchAddress${id}`}
                style={{flex: 1}}
                label={label}
                info={value && address.showWarning && 'Dit adres staat niet in onze database, controleer of dit adres geldig is.'}
                infoIcon='mdi mdi-alert'
                disabled={disabled}
                readOnly={readOnly}
                value={value}
                suggestions={suggestions}
                onChange={this.setValue.bind(this)}
                onChooseSuggestion={this.onChooseSuggestion.bind(this)}
            />
        )
    }
}

export default (AddressSearch)
